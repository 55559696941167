.card-corner-sharp{
    border-radius: 0 !important;
}

.section-card-header{
    margin-right: 132px;
    margin-top: 17px;
}

.header-checkbox{
    margin-left: 55px;
}

.custom-table{
    border-collapse: separate !important;
    border-radius: 10px!important;
    overflow: hidden !important;
    
}

.custom-table  thead  tr{
    background: #f3f3f3;
}

.custom-table  thead tr th{
    padding: 2px
}

.custom-table  tbody tr td{
    padding: 2px
}

.table-first-row-bg{
    background: #e1eaf5;
}
.table-all-row-bg{
    background: #fefefe;
}

.scrollabe-table-container{
     overflow: scroll;
}
.hidden{
    display: none !important;
}
.show{
    display: block !important;
}

.info-icon-primary{
    color:#0071bc !important
}


.word-spacing{
    word-spacing: -1px;
}


