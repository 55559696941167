.leavesToggle{
    .custom-control-description{
        display:none !important
    }
}
.leavesLabelStyle{
    font-weight:500;
}
.leave-balance{
    margin-right: 8%;
  }

  .text-leave-balance{
    color:#0071bc !important
}

@media screen and (min-width: 1665px) {
    .text-leave-balance {
        color:#0071bc !important;
        margin-right: 1%;
    }
  }



