@media (max-width: 767px) {
  .profileNav{
    margin: 0;
  }
}

/*md (medium screen size)*/
@media (min-width: 768px) {

  /*utility classes*/
  .w-md-75{
    width: 75% !important;
  }


  .manage-user-profile.main {
    padding-top: 116px;
  }

  .section-title {
    scroll-margin: 150px;
  }

  .view-user-profile.main {
    padding-top: 140px;
  }
  .view-worker-profile.main {
    padding-top: 94px;
  }

  .view-user-profile.main .section-title{
    scroll-margin: 188px;
  }


  .main-content{
    width: 100%;
  }

  .auth-form{
    max-width: 400px;
  }

  .mt-md-30{
    margin-top:30px;
  }
}

