/*utiliy */
.mt-30 {
  margin-top: 30px;
}

.border-card {
  border-radius: 0.625rem !important;
}

.mw-160 {
  max-width: 160px;
}
.twofa {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
background-color: #0071bc;
}
.text-decoration-underline{
  text-decoration: underline;
}
/* [END] utility */

body,
.nav-link,
.btn,
.form-control,
.file-manager__filters__rows,
.file-manager__filters__rows .custom-select,
.ReactTable .-pagination {
  font-size: 0.938rem;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-tbody .rt-td {
  font-size: 0.938rem !important;
}

.btn-accent,
.btn-primary,
.btn-accent:hover,
.btn-primary:hover,
.btn-accent:active,
.btn-accent:not(:disabled):not(.disabled):active {
  background: #0071bc;
  border: #0071bc;
}


.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item .nav-link.active{
  color: #0071bc;
  box-shadow: inset 0.1875rem 0 0 #0071bc;
}

.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item:hover i{
  font-weight: bold;
  color: black !important;
  
  
}

.main-sidebar .nav .subItem-icon-wrapper i {
  color: unset;
}

.main-sidebar .dropdown-menu .dropdown-item.active,
.main-sidebar .dropdown-menu .dropdown-item.active i {
  color: #0071bc;
}

.main-sidebar .dropdown-menu .dropdown-item:hover,
.main-sidebar .dropdown-menu .dropdown-item:hover i{
  font-weight: bold;
}

.auth-form .card-body {
  box-shadow: inset 0 4px 0 0 #0071bc !important;
}

.auth-form {
  max-width: 100%;
}

.auth-form-errs {
  padding: 0.4375rem 0.75rem;
  font-size: 0.938rem;
 min-height: 38px;
  font-weight: 400;
}

a,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item .nav-link.active i
{
  color: #0071bc;
}



.text-success {
  color: #1E824C !important;
}

.text-warning {
  color: #8D6708 !important;
}

/****************** dark font **************/

::placeholder {
  color: #333 !important
}


body,
.details-card .col span,
.section-title,
.main-sidebar__nav-title,
.page-title,
.page-subtitle,
.copyright,
.table {
  color: #333;
}


h1, h2, h3, h4, h5, h6,
.form-control,
.nav-link,
.nav-item .dropdown-item,
.main-sidebar .nav .nav-item .nav-link,
.nav-item .dropdown-item .material-icons,
.file-manager__filters__rows,
.file-manager__filters__rows .custom-select,
.user-name {
  color: #333;
}

/******************* [end] dark font *****************/


/**************** utility classes ************/
.btn-normal {
  width: 66px;
}


.no-border {
  border: 0;
  box-shadow: none;
}

.text-bold {
  font-weight: 500;
}

@media (min-width: 992px) {
  .filter-control-md {
    width: 160px;
  }
}


/**************** [end] utility classes ************/

.profileNav {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #f5f6f8;
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
}

.profileNav .nav-link {
  cursor: pointer;
  color: #0071bc;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.938rem;
  padding-top: 0;
  border-bottom: 1px solid #00000000;
  padding-bottom: 0.813rem;
}

.manage-user-profile.main {
  padding-top: 200px;
}

.section-title {
  scroll-margin: 270px;
}

.view-user-profile.main {
  padding-top: 150px;
}

.view-user-profile.main .section-title {
  scroll-margin: 322px;
}


.nav-link.active {
  border-bottom: 1px solid #0071bc;
  color: #0071bc;
}

.effectiveDateField {
  max-width: 170px;
}

.auth-form__logo {
  max-width: 5.5rem;
}


#userJourney th:first-child, #userJourney td:first-child {
  padding-left: 20px;
}


.home-showcase {
  margin-top: 7.5rem;
}

.details-card .col .userprofile span {
  display: initial !important;   
  overflow: auto;
  color: red !important;
  height: auto !important;
}

.details-card .col .required {
  display: inline;
  color: red !important;
  overflow: auto;
}

.details-card .col span {
  display: block;
  height: 39px;
  color: #333333 !important;
  overflow: auto;
}

.user-actions {
  min-width: 8rem;
}

.main-navbar .navbar .navbar-nav .dropdown-menu {
  left: 0;
  min-width: 8rem;
}

.nav-link, .icon-cursor{
  cursor: pointer;
}

.profileNav .wrapper {
  height: 48px;
}


.details-card .col span {
  font-weight: 500;
}

.card-body {
  overflow-x: auto;
}


.table {
  color: #333;
}

.table thead th {
  font-weight: 500;
}

.table td {
  font-weight: 400;
}


.main-sidebar .nav .nav-item .nav-link,
.main-sidebar .nav .nav-item .dropdown-item {
  padding: .2375rem 1.5625rem;
}

.h5, .h6, h5, h6 {
  line-height: 1.4rem;
}


.leave-box .box-header {
  font-size: 1rem;
}

.leave-box {
  width: 224px;
  border-radius: 0;
  box-shadow: none;
}

.tableCard {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 2px;
}

.tableSearch {
  margin-bottom: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0.46875rem 2.1875rem #5a61691a, 0 0.9375rem 1.40625rem #5a61691a, 0 0.25rem 0.53125rem #5a61691a, 0 0.125rem 0.1875rem #5a61691a
}

.leaveLabel {
  background-color: #0071bc;
  color: #fff
}

.text-pending {
  color: #755300;
}

.entitled-leaves {
  margin: 0 -5px;
  margin-bottom: 8px;
}

.box-card {
  width: 254px;
}

.ReactTable .rt-thead .rt-th {
  display: flex;
  text-align: center;
  white-space: pre-wrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.ReactTable .rt-tbody .rt-td {
  font-weight: 400;
}


::-webkit-scrollbar {
  height: 6px;
  width: 8px
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  display: none;
}

.entitled-leaves:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  background: #0071bc;
  display: block;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  background: #0071bc;
  display: block;
  border-radius: 10px;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb( / 30%);
  border-radius: 10px;
}


.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.alert-info {
  background-color: #0071bc;
}

.ReactTable .-pagination button:hover {
  color: #fff;
  background: #0071bc !important;
}

.sticky-alert-wrapper {
  top: 60px;
}

.receipt-viewer {
  max-height: 644px;
}

.popup-receipt-viewer {
  width: 100%;
}

.popup-receipt-viewer-pdf {
  height: 100vh;
}

.main-sidebar .nav .nav-item .nav-link i {
  color: #444;
}

.card .section-title {
  font-size: 0.938rem;
}

.ReactTable .rt-noData {
  color: #333;
}


.notes-textarea:focus {
  box-shadow: none;
}


.tabBar::-webkit-scrollbar {
  height: 6px;
}

.tabBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  display: none;
}

.tabBar:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  background: #0071bc;
  display: block;
  border-radius: 10px;
}

.tabBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb( / 30%);
  border-radius: 10px;
}


.subLabel {
  position: absolute;
  right: 18px;
  bottom: 0;
  color: #333;
  min-height: 39px;
}

select.form-control {
  height: 39px !important;
}


.row-action-select {
  max-height: 22px;
}

/*to enable toggle button click event*/
.custom-toggle input[type=checkbox], .custom-checkbox input[type=checkbox], input[type=radio] {
  cursor: pointer;
  width: 38px;
  z-index: 1;
}
.announcementdiv {
  height: calc(100vh - 300px);  /* height = (page height - header-height - footer-height)   */
 overflow: scroll;
}

.primary-alert {
  top: 60px;
  z-index: 1072;
}


.custom-select:disabled {
  background-color: #f5f6f7;
}

.tooltip-icon {
  color: #444;
}

.tooltip {
  font-size: 0.938rem;
}

.tableHeader .tooltip-icon {
  color: #0071bc;
}


.rt-tfoot .rt-td.overflow-visible {
  overflow: visible;
}

.mileageItemsTable .rt-tfoot .rt-td:nth-child(7),
.expenseItemsTable .rt-tfoot .rt-td:nth-child(8) {
  border-left: 0;
}

.messages-col {
  max-height: 844px;
}

.messages-col .messages-list .message-item {
  cursor: pointer;
}

.messages-col .messages-list .message-item.active, .message-item:hover {
  box-shadow: inset 0.1875rem 0 0 #0071bc;
}

.conversations-area {
  white-space: pre-wrap;
}

.messages-col .section-title {
  top: 8px;
}

.messages-col .iconBox {
  top: 0;
}

.conversations-area{
  height:200px
}

.conversations-area.notes-area{
  height:140px
}

#main-logo{
  max-width: 150px;
}

/* .login-container .form-control,
.login-container .form-control:hover{
  border: 2px solid #0071BC;
  padding-top: 12px;
  padding-bottom: 12px;
} */

.login-container .btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* .login-container .form-control::placeholder,
.login-container .form-control::-webkit-input-placeholder,
.login-container .form-control:-ms-input-placeholder {
  font-weight: 500;
  color: #0071BC !important;
} */

.login-container .auth-form__logo {
  max-width: 14.5rem;
}

.login-container .auth-form .card-body {
  box-shadow: none !important;
  padding: 1.575rem 2.975rem;
}

.login-container .auth-form .login-btn {
  border-radius: 8px;
  font-size: 20px;
}

.login-container .auth-form .lock-icon {
  border: 2px solid #0071BC;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

 .login-container .auth-form .password-eye-icon {
  border: 1px solid #e1e5eb;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
} S

.login-container .auth-form .password-eye-icon i {
  cursor: pointer;
}

.login-container .auth-form .card {
  border-radius: 0.925rem;
  color: black;
}

.login-container {
  background: linear-gradient(160deg, #0071BC 100%, #FFFFFF 0%);
}

.nav-signing-btn {
  background: #f9d00b;
}

.passwordborder{
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;

}
.tooltipwidth{
 min-width: 280px!important;
}
.search.input-group.input-group-seamless > .input-group-append
{
  right: auto;
  left: 0;
}
.required {
  color: red !important;
}
.rate-start-date.react-datepicker-wrapper {
  
   
    max-width: 100% !important;
}
.align-input-amount-right{
  text-align: right;
}
.text-unload-page-confirm{
  color:#0071bc !important
}

.custom-tooltip-inner{
  max-width: 100% !important;
  margin-bottom: 17.5%;
}

.conversation-options{
  width: 85%;
}
.message-box{
  z-index: 1 !important;
}
.message-box:focus{
  z-index: 1 !important;
}


.file-badge-toast{
  color:#6fafd9;
  font-weight: bold;
}
.file-badge-toast-deleted{
  font-weight:bold;
   font-style:italic
}
.crossIcon {
  color:#ec2b2b !important
}
.attachment-file{
  font-size: 10px !important;
}

.attachment-file div{
  position: relative;
}
.attachment-file div i{
  position:absolute;
   margin-left:4px;
   right: 10px;
}

.attachment-file-float-right{
  font-size: 10px !important;
}
.attachment-file-float-right div{
  position: relative;
}
.attachment-file-float-right div i{
  position:absolute;
   margin-left:4px;
   right: 0px;
}
.attachment-file-float-right div img{
  float: right;
  margin-right: 10px;
}

.file-badge-text {
  color: #358FCA 
}
.plus-btn-container{
  background-color: #ffffff;
}
.plus-btn-container:hover{
  background-color: #0071BC;
  border-radius: 8px;
}
.plus-btn{
  fill:#0071BC
}

.plus-btn:hover{
  fill:white !important;
}

.k-no-data{
  display: table;
  width: 100%;
  padding-top: 20px;
}
.primary-text{
  color:#0071bc !important
}
.k-stepper .k-step-current .k-step-done .k-step-indicator {
  border-color: #0071bc !important;
  color: white;
  background-color: #0071bc !important;
}

.k-table-sm{
  font-size: 0.938rem !important;
  width: 100% !important;
}
.primary-bg{
  background-color: #0071bc;
  color: white !important
}

.k-state-focused, .k-state-selected{
  background-color: #f8f8f8 !important;
  border-color:  #f8f8f8 !important;
  color:#333 !important;
  
}
.k-state-focused, .k-list>.k-state-focused, .k-listview>.k-state-focused, .k-grid-header th.k-state-focused, td.k-state-focused, .k-button.k-state-focused{
  -webkit-box-shadow: inset 0 0 0px 0px #bcb4b0;
  box-shadow: inset 0 0 0px 0px #bcb4b0;
}
.k-state-hover {
  background-color: #f8f8f8 !important;
  color:#333 !important;
}
.k-picker-solid {
  background-color: white;
  background-image: none;
 }
 .k-list-item.k-selected, .k-selected.k-list-optionlabel, .k-list-item.k-selected:hover,
  .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel{
  background-color: #f8f8f8 !important;
  color:#333 !important;
  box-shadow: none;
 }
 .k-expander.k-focus{
  box-shadow: none;
 }
 .k-expander-content{
  margin: 0px;
  padding: 2px;
  padding-block:
 }
 .k-expander.k-expanded{
  border:none;
 }
 .proposal-item-card-country-input{
  border-left: solid #0071bc;
  border-left-width: 3px !important;
 }
 .proposal-item-card-input{
  border-left: solid #0071bc;
  border-left-width: 3px !important;
  max-width: 190px;
  min-width: 190px;
 }
 .k-expander-header, .k-expander-header:hover{
  color: #212529;
  background-color: #f8f9fa;
  outline: none;
  padding: 5px;
}
.k-expander-title{
  color: #0071bc;
  font-weight: bold;
}

.align-input-amount-right .k-input-inner {
  text-align: right;
}

.align-input-amount-right.k-focus .k-input-inner {
  text-align: left;
}

.align-input-amount-suffix-left .k-input-inner {
  text-align: right;
  padding-right: 0px;
}
.align-input-amount-suffix-left.k-focus .k-input-inner {
  text-align: left;
  padding-right: 0px;
}

.k-stepper .k-step-done .k-step-indicator{
  border-color: #0071bc;
    color: white;
    background-color: #0071bc;
}
.k-stepper .k-step-current .k-step-indicator{
  border-color: #0071bc;
    color: white;
    background-color: #0071bc;

}
.k-stepper .k-step-label span.k-step-text{
  overflow: visible;
  max-width: 100% !important;

}

.k-input-solid .k-input-suffix {
  color: #212529;
}

/* .k-list-container {
  height: 200px !important;
  overflow: scroll;
} */

.disabled-anchor {
  pointer-events: none;
  cursor: pointer;
}

.no-item-icon{
  background: #c3173b;
  color: white;
  height: 14px;
  width: 14px;
}

.k-expander-title{
  max-width: 80%;

}
.custom-header .ReactTable .rt-thead .rt-th{
  border-right: 1px solid rgb(0 0 0 / 12%) !important;
}

.submenu-dropdown {
  padding: .2375rem 2.9625rem!important;
}
.submenu-toggle{
  padding: .2375rem 1.2220rem !important
}
.react-dropdown-select-input::placeholder{
  color:#333 !important;
  font-weight: lighter !important;
  font-size: 0.98rem !important;
}
.no-border-footer .rt-tfoot .rt-td{
  border:none;
}
.searchWrapper {
  display: inline-block;
  width: 100%;
  padding: .375rem 1.75rem .375rem .75rem !important;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e') right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  appearance: none;
}
.separated-header .ReactTable .rt-thead .rt-th:has(.separator){
  border-left: 2px solid #e1e5eb !important;
}
.custom-modal .modal-body{
  max-width: 1300px!important;
  overflow-y:auto !important;
  overflow-x: hidden !important;
  max-height:518px;
  padding: 0 0 !important;
  }
  .custom-modal .modal-title {
    width: 100% !important;
}
.custom-modal .modal-content {
  width: 100%;
  z-index:3;
  border: solid #676767 !important;
  
}
.custom-modal .modal-dialog {
  overflow:auto !important;
  max-height:900px !important;
  margin: 0.876rem auto !important;
}
.custom-modal .modal{
  z-index:1071 !important;
  }
.custom-modal .modal-header .modal-title{
  font-size: 0.98rem;
  font-weight: bold;
  margin-right: .5rem !important;
  margin-left: .5rem !important;
  }
  
.lg-modal .modal-dialog{
  max-width: 1300px!important;
}
.searchBox{
  width: 100%;
}